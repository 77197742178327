<template>
  <div>
    <v-toolbar dense class="rounded">
      <v-btn icon class="hidden-xs-only" @click="$router.push('/gametype')">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>

      <v-toolbar-title>Saudi Cup 2024 </v-toolbar-title>

      <v-spacer></v-spacer>
    </v-toolbar>
    <template>
  <v-expansion-panels focusable class="ml-0 pa-1 center">
    <v-expansion-panel>
      <v-expansion-panel-header>Terminos y Condiciones</v-expansion-panel-header>
      <v-expansion-panel-content>
        <p>
    <strong>Acierta el Ganador de la Saudi Cup 2024 y Participa.</strong>
</p>
<p>
    Tenemos 400$ a repartir
</p>
<p>
    Son <strong>4 Premios de 100$</strong>(Dinero Real)
</p>
<p>
    Registrate en
    <a href="http://www.trackandraces.com/">
        <u>www.trackandraces.com</u>
    </a>
</p>
<p>
    Busca el link de la Saudi CUP, escoge un ejemplar y si  resulta
    ganador,  vas al sorteo el día <strong>Martes 27 de Febrero 2024.</strong>
</p>
<p>
    Puedes participar hasta las <strong>11:59 am hora de Venezuela.</strong>
</p>
<p>
  Los Ganadores se escogen de manera aleatoria en un programa en vivo por las redes 
  de @trackandraces el dia Martes a las 08:00 pm hora de Venezuela en vivo y directo.
</p>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>
    <div class="mt-2 ml-1 mr-1 rounded white pa-2 text-center">
      <div>
        <span class="font-weight-black ">Tu Seleccion: </span>
        <h3>{{ seleccion() }}</h3>
        <span class=" red--text font-weight-black center">Total de Votos: {{ total }} </span>
      </div>
      <v-btn
        v-if="Object.keys(selected).length > 0 && caballo==''"
        class="mx-3 pt-2 pb-2"
        fab
        dark
        @click="dialogPreg = true"
        color="error"
      >
        <v-icon dark>
          mdi-check-bold
        </v-icon>
      </v-btn>
      <v-dialog v-model="dialogPreg" persistent max-width="290">
          <v-card>
            <v-card-title class="text-h5">
              {{seleccion()}}
            </v-card-title>
            <v-card-text
              >Esta Seguro de su eleccion? esta accion no se podra revertir</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="green darken-1" text @click="dialogPreg = false">
                Cancelar
              </v-btn>
              <v-btn color="green darken-1" text @click="guardarSeleccion()">
                Aceptar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
       <template>
        <v-data-table v-show="caballo==''"
          v-model="selected"
          :headers="headers"
          :items="desserts"
          :items-per-page="20"
          hide-default-footer
          item-key="name"
          single-select="true"
          show-select
          :disabled="disabled"
          class="font-weight-black text-left"
        >
          <template v-slot:top> </template>
        </v-data-table>
      </template> 
    </div>
    <div class="mt-2 ml-1 mr-1 rounded white pa-2 ">
      <template>
        
  <v-simple-table dense>
    <template v-slot:default>
        
      <thead>
        <tr>
          <th class="text-left">
            Ejemplar
          </th>
          <th class="text-left">
            Votos
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in ejemplaresList"
          :key="item.ejemplar"
        >
          <td><v-progress-linear color="indigo" height="25" :value="Math.ceil(item.votos)" striped>{{ Math.ceil(item.votos) }}</v-progress-linear></td>
          <td>{{ item.ejemplar }}</td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>
    </div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      caballo: "",
      total: 1,
      hora:"",
      dialogPreg: false,
      ejemplaresList: [],
      usuarioList: [],
      selected: [],
      headers: [
        {
          value: "name",
          disabled:false,

          sortable: false,
        },
      ],
      desserts: [
        {
          name: "POWER IN NUMBERS",
        },
        {
          name: "WHITE ABARRIOS",
        },
        {
          name: "USHBA TESORO",
        },
        {
          name: "NATIONAL TREASURE",
        },
        {
          name: "DERMA SOTOGAKE",
        },
        {
          name: "LEMON POP",
        },
        {
          name: "CROWN PRIDE",
        },
        {
          name: "MEISHO HARIO",
        },
        {
          name: "DEFUNDED",
        },
        {
          name: "HOIST THE GOLD",
        },
        {
          name: "SAUDI CROWN",
        },
        {
          name: "SENOR BUSCADOR",
        },
        {
          name: "ISOLATE",
        },
        {
          name: "CARMEL ROAD",
        },
      ],
    };
  },
  mounted() {
    this.fecha();
    this.getConcurso();
    this.getVotos();
  },
  methods: {
    seleccion() {
      if (this.caballo == "") {
        if (Object.keys(this.selected).length == 0) {
          return "-";
        }else{
          return this.selected[0].name
        }
      }else{
        return this.caballo
      }
    },
    getConcurso() {
      axios({
        method: "get",
        url: `/getconcurso`,
      }).then((response) => {
        //console.log(response);
        //this.promo = response.data.promo[0];
        this.caballo = response.data.seleccion[0].ejemplar;
        //console.log(this.promo.venta);
      });
    },
    fecha(){

      axios({
        method: "get",
        url: `/fechayhora`,
      }).then((response) => {
        //this.promo = response.data.promo[0];
        this.hora = new Date(response.data.fechadate+' '+response.data.horadate);
        
        //console.log(this.promo.venta);
      });

    },
    getVotos() {
      axios({
        method: "get",
        url: `/getvotos`,
      }).then((response) => {
        console.log(response);
        //this.promo = response.data.promo[0];
        this.usuarioList = response.data.usuarios;
        this.ejemplaresList = response.data.ejemplares;
        this.total = response.data.total;
        //console.log(this.promo.venta);
      });
    },
    guardarSeleccion() {
      if (Object.keys(this.selected).length == 0) {
        alert("Seleccione a un ejemplar");
      } else {
        axios({
          method: "post",
          url: `/guardarconcurso`,
          data: {
            ejemplar: this.selected[0].name,
          },
        }).then((response) => {
          this.$store.state.msg = response.data.mensaje;
          alert(response.data.mensaje);
          this.getConcurso();
        });
      }
      this.dialogPreg = false;
      
    },
    confirmar() {
      alert("eso es");
    },
  },
};
</script>

<style>

</style>
